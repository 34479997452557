import { useAuthStore } from "@/stores/authStore";
import { showConnect } from "@stacks/connect";
import router from "@/router";

export function useAuthentication() {
  return {
    logout(): void {
      const authStore = useAuthStore();
      authStore.userSession?.signUserOut();
      authStore.$patch({ userData: null, shrooms: null });
      router.push("/");
    },
    login(): void {
      const authStore = useAuthStore();
      showConnect({
        appDetails: {
          name: "Nonnish",
          icon: window.location.origin + "/example.png",
        },
        redirectTo: "/",
        onFinish: () => {
          const userData = authStore.userSession?.loadUserData();
          authStore.userData = userData;
          router.push("/mint");
        },
        userSession: authStore.userSession,
      });
    },
  };
}
